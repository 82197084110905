import { GoogleLogin } from 'react-google-login';
import { useNavigate } from 'react-router-dom';

const clientId = "503154911349-34mnmvbjeeu65s654k5ob9hiqltnk398.apps.googleusercontent.com";

function Login() {
    const navigate = useNavigate();

    const onSuccess = (res) => {
        console.log("LOGIN SUCCESS! Current user: ", res.profileObj);
        navigate('/landing-page');
    }

    const onFailure = (res) => {
        console.log("LOGIN FAILED! res: ", res);
    }

    return (
        <div id="signInButton">
            <GoogleLogin
                clientId={clientId}
                buttonText="Login"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
            />
        </div>
    )
}

export default Login;