import { GoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';

const clientId = "503154911349-34mnmvbjeeu65s654k5ob9hiqltnk398.apps.googleusercontent.com";

function Logout() {

    const navigate = useNavigate();

    const onSuccess = (res) => {
        console.log("Log out successfull!");
        navigate('/');
    }

    return (
        <div id="signOutButton">
            <GoogleLogout
                clientId={clientId}
                buttonText="Logout"
                onLogoutSuccess={onSuccess}

            />
        </div>
    )
}

export default Logout;