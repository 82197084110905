import React from "react";
import { Routes, Route } from "react-router-dom";
import logo from "./colorLogoNoBack.svg";
import "./App.css";
//import GoogleLoginButton from './components/GoogleLoginButton';
import LoginButton from "./components/login";
//import LogoutButton from "./components/logout";
import LandingPage from "./pages/LandingPage/LandingPage";
import { useEffect } from "react";
import { gapi } from "gapi-script";

const clientId =
  "503154911349-34mnmvbjeeu65s654k5ob9hiqltnk398.apps.googleusercontent.com";

function App() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="Logo-container">
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="Login-container">
                <LoginButton />
              </div>
            </>
          }
        />
        <Route path="/landing-page" element={<LandingPage />} />
      </Routes>
    </div>
  );
}

export default App;
