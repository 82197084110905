// src/pages/LandingPage/LandingPage.jsx

import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import LogoutButton from "../../components/logout";
import axios from "axios";

const LandingPage = () => {
  
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [documentGenerated, setDocumentGenerated] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    const allowedExtensions = ["xlsx", "xls", "xlsm"];

    if (allowedExtensions.includes(fileExtension)) {
      setFile(selectedFile);
      setErrorMessage("");
    } else {
      setErrorMessage("Por favor, faça upload de um arquivo Excel (.xlsx, .xls, .xlsm).");
      event.target.value = null; // Resetar o campo de arquivo
    }
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    if (!file) {
      setErrorMessage("Por favor, selecione um arquivo Excel para fazer upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    axios.post("https://django.tunanzaki.ao/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log("Documento enviado e processado:", response.data);
        setErrorMessage("");

        // After successful DOCX generation, trigger the PDF conversion
        axios.get("https://django.tunanzaki.ao/convert_document/")
          .then((pdfResponse) => {
            console.log("Documento PDF gerado:", pdfResponse);
            setDocumentGenerated(true);
          })
          .catch((pdfError) => {
            console.error("Erro ao gerar o PDF:", pdfError);
            setErrorMessage("Erro ao gerar o PDF.");
          });
      })
      .catch((error) => {
        console.error("Erro ao enviar o arquivo:", error);
        setErrorMessage("Erro ao enviar o arquivo.");
      });
  };

  const handleDownload = () => {
    window.open("https://django.tunanzaki.ao/download/", "_blank");
  };

  /*const handleViewPdf = () => {
    window.open('https://django.tunanzaki.ao/view/', '_blank');
  };*/

  const handleViewPdf = async () => {
    try {
        const response = await fetch('https://django.tunanzaki.ao/view/');
        if (response.ok) {
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            window.open(objectURL, '_blank');
        } else {
            console.error('Erro ao buscar o caminho do PDF:', response.statusText);
        }
    } catch (error) {
        console.error('Erro ao buscar o caminho do PDF:', error);
    }
};

  const handleDelete = () => {
    axios.post("https://django.tunanzaki.ao/delete/")
      .then((response) => {
        console.log("Documento excluído:", response.data);
        setDocumentGenerated(false);
      })
      .catch((error) => {
        console.error("Erro ao excluir o arquivo:", error);
      });
  };

  return (
    <div className="landingPage">
      <div className="logoutButtonContainer">
        <LogoutButton />
      </div>
      <h1>Bem-vindo ao Nkanga Nzaki</h1>
      <p>Aqui o contabilista fecha rápido as contas.</p>

      <form onSubmit={handleFileUpload}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit" className="createDocumentButton">
          Enviar Arquivo
        </button>
      </form>

      {documentGenerated && (
        <div>
          <button onClick={handleViewPdf}>Visualizar Documento</button>
          <button onClick={handleDownload}>Baixar Documento</button>
          <button onClick={handleDelete}>Excluir Documento</button>
        </div>
      )}

      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
};

export default LandingPage;
